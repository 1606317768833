import { Link, LinkProps } from '@swe/shared/providers/router/link';
import { useBreakpoint } from '@swe/shared/tools/media';
import { Carousel } from '@swe/shared/ui-kit/components/carousel-v2';
import { useContainerBPDiscreteWidth } from '@swe/shared/ui-kit/components/container/utils/use-extend-el-to-container';
import { SectionHeading } from '@swe/shared/ui-kit/components/section-heading';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import { useMemo, MouseEvent } from 'react';

import styles from './styles.module.scss';

import DiscountImage from 'common/components/discount-image';
import { Routes } from 'common/router/constants';
import { buildNamedId } from 'common/router/utils';
import useCatalogQuery from 'domains/catalog/use-cases/use-catalog-query';
import { Banner as BannerEntity } from 'entities/banner/banner';
import { CompilationType } from 'entities/common/compilation';
import { getBannerAlt } from 'entities/common/seo';

type BannerProps = {
  items: BannerEntity[];
  total?: number;
  onViewAllClick?: () => void;
  onClickBanner?: (data: {
    event: MouseEvent;
    id: BannerEntity['id'];
    index: number;
    promoId: string;
    length: number;
    href?: LinkProps<Routes>['href'];
  }) => void;
} & ComponentHasClassName;

export type { BannerProps };

export const Banner = ({ items = [], total, onViewAllClick, className, onClickBanner }: BannerProps) => {
  const imageWidth = useContainerBPDiscreteWidth();
  const { mobile } = useBreakpoint();
  const { buildCatalogLink } = useCatalogQuery();

  const bannersWithImage = useMemo(
    () => items.filter((banner) => !!(mobile ? banner.mobileImage : banner.image)),
    [items, mobile],
  );

  const carouselItems = useMemo(
    () =>
      bannersWithImage.map(({ id, image, mobileImage, name, promo, link }, index) => {
        const href =
          link ??
          (promo
            ? buildCatalogLink({
                filters: null,
                searchTerm: null,
                subPath: [CompilationType.DISCOUNT, buildNamedId(promo.name, promo.id)],
                page: null,
              })
            : undefined);

        const Component = href ? Link : 'div';

        return (
          <Component
            key={id}
            href={href!}
            className={styles.item}
            onClick={(event: MouseEvent) =>
              onClickBanner?.({
                id,
                event,
                index,
                href,
                length: bannersWithImage.length,
                promoId: String(promo?.id || ''),
              })
            }
          >
            <DiscountImage
              width={imageWidth}
              bannerUrl={image}
              mobileBannerUrl={mobileImage}
              isMobile={mobile}
              alt={getBannerAlt(name)}
              lazy={index !== 0}
            />
          </Component>
        );
      }),
    [bannersWithImage, buildCatalogLink, imageWidth, mobile, onClickBanner],
  );

  if (carouselItems.length === 0) return null;

  return (
    <div className={className}>
      <SectionHeading
        level={2}
        size={mobile ? 'md' : 'lg'}
        link={
          onViewAllClick && !!total
            ? {
                children: `View all ${total}`,
                onClick: onViewAllClick,
              }
            : undefined
        }
      >
        Featured Deals
      </SectionHeading>
      <Carousel
        items={carouselItems}
        extendToView
        gap="equal"
        autoplay
        infinite
        thumbs={false}
      />
    </div>
  );
};
