import { useBreakpoint } from '@swe/shared/tools/media';
import Button from '@swe/shared/ui-kit/components/button';
import { DiscountLabelIcon, FilterIcon, ThumbUpIcon } from '@swe/shared/ui-kit/components/icon';
import Stack from '@swe/shared/ui-kit/components/stack';

import { useCallback, useMemo } from 'react';

import styles from './styles.module.scss';

import { useAnalytics } from 'common/providers/analytics';
import { AEventType } from 'common/providers/analytics/constants';
import { useStoreConfig } from 'common/providers/config';
import { useCurrentUser } from 'common/providers/user';
import { buildCarouselNamedId } from 'common/router/utils';
import { usePlatformOs } from 'common/use-cases/use-platform-os';
import { useSaleType } from 'common/use-cases/use-sale-type';
import useCatalogQuery from 'domains/catalog/use-cases/use-catalog-query';
import { useBannersModal } from 'domains/product/containers/banners-modal';
import { useDealsModal } from 'domains/product/containers/deals-modal';
import { Banner, BannerProps } from 'domains/shop/components/banner';
import { CarouselDeals } from 'domains/shop/components/carousel-deals';
import { CarouselProducts } from 'domains/shop/components/carousel-products';
import { Categories } from 'domains/shop/components/categories';
import { useShowAllCategory } from 'domains/shop/containers/home/use-cases/use-show-all-category/';
import { GetCarouselBannersEndpoint } from 'endpoints/banner/get-carousel-banners';
import GetCarouselList from 'endpoints/product/get-carousel-list';
import GetCategoryListEndpoint from 'endpoints/product/get-category-list';
import { GetPromoBannerListEndpoint } from 'endpoints/promotion/get-promotion-list';
import { CommonFilters } from 'entities/product/filter';

const Home = () => {
  const { toCatalog } = useCatalogQuery();
  const goToCatalog = useCallback(() => toCatalog({ filters: null, searchTerm: null, subPath: null }), [toCatalog]);
  const goToDeals = useCallback(
    () => toCatalog({ filters: CommonFilters.DEALS, searchTerm: null, subPath: null }),
    [toCatalog],
  );
  const goToBestSellers = useCallback(
    () => toCatalog({ filters: CommonFilters.POPULAR, searchTerm: null, subPath: null }),
    [toCatalog],
  );
  const { saleType } = useSaleType();
  const { title, homePageDealsCarouselMaxCount, homePageDealsCarouselIsEnabled } = useStoreConfig();
  const heading = `${title} Dispensary Menu`.trim();
  const { mobile } = useBreakpoint();
  const { platformOs } = usePlatformOs();
  const { data: deals = [] } = GetPromoBannerListEndpoint.useRequest(
    { saleType, platformOs },
    { headers: { SaleType: saleType as string } },
    { keepPreviousData: true },
  );
  const { data: banners } = GetCarouselBannersEndpoint.useRequest(
    { saleType, platformOs },
    {},
    { keepPreviousData: true },
  );
  const { data: _categories = [] } = GetCategoryListEndpoint.useRequest(
    { saleType },
    { headers: { SaleType: saleType as string } },
    { keepPreviousData: true },
  );
  const { data: carousels = [] } = GetCarouselList.useRequest(
    {
      saleType,
      platformOs,
    },
    undefined,
    {
      keepPreviousData: true,
    },
  );

  const nonEmptyCarousels = carousels.filter((carousel) => carousel.products.length > 0);
  const categories = useShowAllCategory(_categories);
  const dealsModal = useDealsModal();
  const bannersModal = useBannersModal();

  const allDeals = useMemo(() => deals.filter((banner) => banner.displayInDiscountMenu), [deals]);
  const carouselDeals = useMemo(() => {
    if (!homePageDealsCarouselIsEnabled) return [];
    if (typeof homePageDealsCarouselMaxCount !== undefined) {
      return allDeals.slice(0, homePageDealsCarouselMaxCount);
    }
    return allDeals;
  }, [allDeals, homePageDealsCarouselIsEnabled, homePageDealsCarouselMaxCount]);

  const { pushEvent } = useAnalytics();
  const { user } = useCurrentUser();

  const handlerOnClickBanner = useCallback(
    ({ id, index, promoId, length, href }: Parameters<NonNullable<BannerProps['onClickBanner']>>[0]) => {
      pushEvent(AEventType.BANNER_CLICK, {
        banner: {
          banner_id: String(id),
          banner_link: typeof href === 'string' ? href : href ? `${href.basePath}/${href.pathname}?${href.query}` : '',
          account_id: user ? user.accountId : 0,
          position: index + 1,
          creative_id: promoId,
        },
        carousel: {
          num_banners: length,
        },
        page: {
          url: window.location.href,
          referrer: document.referrer,
        },
      });
    },
    [pushEvent, user],
  );

  return (
    <Stack spacing={{ xs: 'md', sm: 'xl' }}>
      <Banner
        key="banner"
        items={banners?.banners ?? []}
        total={banners?.viewAllCount}
        onViewAllClick={bannersModal.open}
        onClickBanner={handlerOnClickBanner}
      />
      <div className={styles.catalogLinks}>
        <Button
          className={styles.catalogLink}
          color="light"
          onClick={goToDeals}
          name="deals"
          icon={!mobile && DiscountLabelIcon}
        >
          Deals
        </Button>
        <Button
          className={styles.catalogLink}
          color="light"
          onClick={goToBestSellers}
          name="best sellers"
          icon={!mobile && ThumbUpIcon}
        >
          Popular
        </Button>
        <Button
          className={styles.catalogLink}
          color="light"
          onClick={goToCatalog}
          name="search & filters"
          icon={!mobile && FilterIcon}
        >
          {mobile ? 'Filter' : 'Search & Filter'}
        </Button>
      </div>

      {categories?.length > 0 && (
        <Categories
          key="categories"
          items={categories}
          heading={heading}
        />
      )}

      {carouselDeals.length > 0 && (
        <CarouselDeals
          key="carouselDeals"
          title="All deals"
          items={carouselDeals}
          total={allDeals.length}
          onViewAll={dealsModal.open}
        />
      )}

      {nonEmptyCarousels.map((carousel) => {
        return (
          <CarouselProducts
            key={carousel.id}
            {...carousel}
            onViewAll={() =>
              toCatalog({
                subPath: [buildCarouselNamedId(carousel.name, carousel.id)],
                searchTerm: null,
                filters: null,
              })
            }
          />
        );
      })}

      <Button
        className={styles.footerCatalogLink}
        onClick={goToCatalog}
        name="shop all"
      >
        Shop All Products
      </Button>
      {dealsModal.modal}
      {bannersModal.modal}
    </Stack>
  );
};

export { Home };
export default Home;
