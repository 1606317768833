import createEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { PlatformOs } from 'common/use-cases/use-platform-os';

import { Banner } from 'entities/banner/banner';
import { StoreSaleType } from 'entities/shop/sale-type';

type Data = {
  banners: Banner[];
};

type Params = {
  saleType?: StoreSaleType;
  platformOs: PlatformOs;
};

const NAME = '/Banners/GetViewAll';
const GetAllBannersEndpoint = createEndpoint<Params, Data>(NAME);

export default GetAllBannersEndpoint;
export { GetAllBannersEndpoint };
