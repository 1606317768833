import createEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { ProductCategory } from 'entities/product/product';
import { StoreSaleType } from 'entities/shop/sale-type';

type Params = {
  saleType?: StoreSaleType;
};
type Data = ProductCategory[];

const NAME = '/Products/GetProductCategoryList';
const GetCategoryListEndpoint = createEndpoint<Params, Data>(NAME);

export default GetCategoryListEndpoint;
