import Link from '@swe/shared/providers/router/link';
import { CarouselScrollable } from '@swe/shared/ui-kit/components/carousel';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

import AssetCard from 'common/components/asset-card';
import { buildNamedId } from 'common/router/utils';
import useCatalogQuery from 'domains/catalog/use-cases/use-catalog-query';
import { getCategoryAlt } from 'entities/common/seo';
import { ProductCategory } from 'entities/product/product';

const PLACEHOLDER =
  'https://sweedpos.s3.amazonaws.com/store/production/1707122555_5a51d903-0edd-413b-a8fc-79d8fe60a279.png';

const MAX_NOT_LAZY_ITEMS = 10;

export type CategoriesProps = {
  items: ProductCategory[];
  heading: string;
} & ComponentHasClassName;

export const Categories = ({ className, items = [], heading }: CategoriesProps) => {
  const { buildCatalogLink } = useCatalogQuery();
  return (
    <CarouselScrollable
      headingLevel={1}
      title={heading}
      className={className}
      spacing={{ xs: 'xxs', sm: 'sm' }}
    >
      {items.map((item, index) => {
        return (
          <AssetCard
            as={Link}
            className={styles.item}
            alt={getCategoryAlt(item.name)}
            image={item.imageUrl ?? PLACEHOLDER}
            title={item.name}
            key={item.id}
            href={buildCatalogLink({
              filters: null,
              subPath: item.filter ? [buildNamedId(item.name, item.tagId)] : [],
              searchTerm: null,
              page: null,
            })}
            lazy={index >= MAX_NOT_LAZY_ITEMS}
          />
        );
      })}
    </CarouselScrollable>
  );
};
