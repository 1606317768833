import createEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { PlatformOs } from 'common/use-cases/use-platform-os';

import { Banner } from 'entities/banner/banner';
import { StoreSaleType } from 'entities/shop/sale-type';

type Data = {
  banners: Banner[];
  viewAllCount: number;
};

type Params = {
  saleType?: StoreSaleType;
  platformOs: PlatformOs;
};

const NAME = '/Banners/GetBanners';
const GetCarouselBannersEndpoint = createEndpoint<Params, Data>(NAME);

export default GetCarouselBannersEndpoint;
export { GetCarouselBannersEndpoint };
