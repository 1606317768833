import { CarouselScrollable } from '@swe/shared/ui-kit/components/carousel';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import { DiscountCard } from 'domains/shop/components/discount-card';
import { Discount } from 'entities/discount/discount';

export type CarouselDealsProps = {
  title: string;
  items: Discount[];
  total: number;
  onViewAll?: () => void;
} & ComponentHasClassName;

const CarouselDeals = ({ title, items, className, total, onViewAll }: CarouselDealsProps) => {
  return (
    <CarouselScrollable
      title={title}
      link={onViewAll ? { children: `View all ${total}`, onClick: onViewAll } : undefined}
      className={className}
      spacing={{ xs: 'xxs', sm: 'sm' }}
    >
      {items.map((item) => (
        <DiscountCard {...item} />
      ))}
    </CarouselScrollable>
  );
};

export { CarouselDeals };
