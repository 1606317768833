import Link from '@swe/shared/providers/router/link';
import { Badge } from '@swe/shared/ui-kit/components/badge';
import CroppedText from '@swe/shared/ui-kit/components/cropped-text';
import { PercentIcon } from '@swe/shared/ui-kit/components/icon';
import { Text } from '@swe/shared/ui-kit/components/text';

import React from 'react';

import styles from './styles.module.scss';

import { buildNamedId } from 'common/router/utils';
import useCatalogQuery from 'domains/catalog/use-cases/use-catalog-query';
import { DiscountInfo } from 'domains/product/components/discount-card/components/discount-info';
import { CompilationType } from 'entities/common/compilation';
import { Discount } from 'entities/discount/discount';

const DiscountCard = ({ id, name, scheduleDisplayText, fulfilmentTypes }: Discount) => {
  const { buildCatalogLink } = useCatalogQuery();
  return (
    <Link
      className={styles.root}
      href={buildCatalogLink({
        subPath: [CompilationType.DISCOUNT, buildNamedId(name, id)],
        filters: null,
        searchTerm: null,
        page: null,
      })}
      color="ghost"
    >
      <div className={styles.info}>
        <CroppedText
          truncatedLines={2}
          expandable={false}
        >
          <Text
            variant="headline"
            size="xxs"
            className={styles.title}
          >
            {name}
          </Text>
        </CroppedText>
        <DiscountInfo
          fulfilmentTypes={fulfilmentTypes}
          schedule={scheduleDisplayText}
        />
      </div>
      <Badge
        icon={PercentIcon}
        color="danger"
        size="md"
      />
    </Link>
  );
};

export { DiscountCard };
