import { useStoreConfig } from 'common/providers/config';
import { ProductCategory } from 'entities/product/product';

const ALL_CATEGORY =
  'https://sweedpos.s3.amazonaws.com/store/production/1707122854_5e1d127a-2cf8-4e00-bd7c-bb937438ddfb.png';

const useShowAllCategory = (categories: ProductCategory[]): ProductCategory[] => {
  const { showShopAllCategory, shopAllCategoryImg } = useStoreConfig();

  if (showShopAllCategory) {
    return [
      {
        name: 'All Products',
        id: -1,
        imageUrl: shopAllCategoryImg || ALL_CATEGORY,
        tagId: -1,
      },
      ...categories,
    ];
  }

  return categories;
};

export default useShowAllCategory;
export { useShowAllCategory };
