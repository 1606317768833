import { useModalRoute } from '@swe/shared/providers/router/modal';
import { useCallback } from 'react';

import { ModalRoutes } from 'common/router/constants';
import BannersModal from 'domains/product/components/banners-modal';
import { BannersList } from 'domains/product/containers/banners-list';

const useBannersModal = () => {
  const { open: _open, close, isOpened } = useModalRoute(ModalRoutes.Banners);
  const open = useCallback(() => _open(), [_open]);

  return {
    open,
    close,
    modal: (
      <BannersModal
        visible={isOpened}
        onClose={close}
      >
        {(viewMode) => <BannersList viewMode={viewMode} />}
      </BannersModal>
    ),
  };
};

export { useBannersModal };
